<template>
  <el-dialog
    :title="'Change Reason'"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item label="Reason" prop="reason">
            <el-select
              v-model="form.reason"
              filterable
              placeholder="Select reason"
            >
              <el-option
                v-for="(reason, index) in reasons"
                :key="index"
                :label="reason"
                :value="reason"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            v-if="form.reason === 'Other'"
            label="Enter your reason here"
            prop="reason"
          >
            <el-input type="text" v-model="form.other_reason" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="primary"
        :loading="changing"
        :disabled="form.reason === ''"
        @click="change"
        >Change reason</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import payment from "@/requests/gardeners/payment-tracker";

export default {
  name: "PaymentUpdateReason",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        reason: "",
        other_reason: "",
      },
      reasons: [
        "Commitment",
        "Financial constraints",
        "Gift",
        "Logistics delays",
        "No answer",
        "One-off",
        "Personal reasons",
        "Poor cleaning service",
        "Poor laundry service",
        "Poor meal service",
        "Promo",
        "Refunded",
        "Relocated",
        "Traveled",
        "Trial",
        "Other",
      ],
      changing: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        const { reason } = this.customer;
        if (reason === "Not Applicable") {
          return;
        }
        if (this.reasons.includes(reason)) {
          this.form.reason = this.customer.reason;
        } else {
          this.form.reason = "Other";
          this.form.other_reason = reason;
        }
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    change() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;

        const payload = {
          tracker_id: this.customer.tracker_id,
          reason:
            this.form.reason === "Other"
              ? this.form.other_reason
              : this.form.reason,
        };

        payment
          .update(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$emit("success");
              this.closeEvent();
            }
            this.changing = false;
          })
          .catch((error) => {
            this.changing = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss"></style>
